<!--    菜单管理-->
<template>
    <div class="Menu" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleCreate" size="small" v-if="hasPrivilege('menu.system.menu.create')">
                新建
            </el-button>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="700"
                row-key="code"
                :tree-props="{ children: 'list' }"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />

                <el-table-column label="名称" width="220" prop="name" v-if="showColumn('name')" />
                <el-table-column label="类型" width="100" prop="level" align="center" v-if="showColumn('level')">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.level === 0" size="small">目录 </el-tag>
                        <el-tag v-else-if="scope.row.level === 1" size="small" type="success">菜单 </el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    label="排序"
                    width="80"
                    prop="orderIndex"
                    align="center"
                    v-if="showColumn('orderIndex')"
                />
                <el-table-column
                    label="权限基本标识"
                    width="200"
                    prop="privilegeFlag"
                    :show-overflow-tooltip="true"
                    v-if="showColumn('privilegeFlag')"
                />
                <el-table-column
                    label="操作"
                    width="150"
                    header-align="center"
                    v-if="showColumn('operate')"
                    prop="operate"
                    key="operate"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="small"
                            type="warning"
                            @click="handleEdit(scope.row)"
                            v-if="hasPrivilege('menu.system.menu.edit')"
                        >
                            编辑
                        </el-button>
                        <el-button
                            size="small"
                            type="danger"
                            @click="handleEnableOrDisable(scope.row.code, !scope.row.valid)"
                            v-if="hasPrivilege('menu.system.menu.delete') && scope.row.valid"
                        >
                            禁用
                        </el-button>
                        <el-button
                            size="small"
                            type="primary"
                            @click="handleEnableOrDisable(scope.row.code, !scope.row.valid)"
                            v-if="hasPrivilege('menu.system.menu.delete') && !scope.row.valid"
                        >
                            启用
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
import Util from '../../../js/Util';
import UrlUtils from '../../../js/UrlUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    components: { CheckTableShowColumnDialog },

    mixins: [CheckTableShowColumn],
    name: 'Menu',
    data() {
        return {
            form: {},
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            loading: false,
            tableData: [],
            url: {
                page: '/system/menu/listTree',
                enableOrDisable: '/system/menu/enableOrDisable',
            },
        };
    },
    mounted() {
        this.handleQuery();
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleCreate() {
            Util.nameJump(this, 'menu.system.menu.create', ['系统管理', '菜单管理', '新增菜单']);
        },
        handleEdit(row) {
            Util.nameJump(this, 'menu.system.menu.edit', ['系统管理', '菜单管理', '编辑菜单'], {
                code: row.code,
            });
        },
        handleEnableOrDisable(code, valid) {
            const param = { code: code, valid: valid };
            UrlUtils.PostRemote(this, this.url.enableOrDisable, param, null, () => {
                this.$message.success('操作成功');
                this.handleQuery();
            });
        },
    },
};
</script>
